import {
  type ConsentData,
  type ConsentEntry,
  type ConsentVersion,
  type LocaleCode,
  CountryConfig,
  ReferenceConfig,
} from "./consent";

/**
 * Returns the IVV consent language based on the hiring country, the number of required
 * documents and the locale.
 *
 * If the country passed in does not exist in the consent data, the global language is
 * used. The required number of documents is used to select the correct version of the
 * consent language.
 *
 * @param hiringCountry The country the candidate is onboarding from
 * @param requiredDocuments The number of required documents for the hiring country
 * @param locale The locale to use for the consent content
 * @returns The IVV consent language to be shown to the user
 */
export const getConsent = (
  hiringCountry: string,
  requiredDocuments: number,
  locale?: string
): ConsentVersion => {
  if (hiringCountry in consentData) {
    return getConsentForLocale(hiringCountry as ConsentEntry, locale);
  }
  if (requiredDocuments == 1) {
    return getConsentForLocale("GLOBAL-1", locale);
  }
  return getConsentForLocale("GLOBAL-2", locale);
};

/**
 * This is a helper that will recursively follow region references
 * can return the appropriate consent language
 * @param entry The original ConsentEntry who's reference pointer will be followed to content
 * @param locale The locale of the language
 * @returns The IVV consent language for the given ConsentEntry
 */
const getConsentForLocale = (
  entry: ConsentEntry,
  locale?: string
): ConsentVersion => {
  if ("content" in consentData[entry]) {
    const consent = consentData[entry] as CountryConfig;
    if (!locale) {
      // If no locale is provided, return the default
      return consent.content.default;
    }
    const localeCode = locale as LocaleCode;
    if (consent.content[localeCode]) {
      return consent.content[localeCode];
    }
    // If there is no specific language for that locale return the default
    return consent.content.default;
  }
  // Recurse to resolve region reference
  const consentRef = consentData[entry] as ReferenceConfig;
  return getConsentForLocale(consentRef.region, locale);
};

/**
 * This is the IVV consent language data that should be shown to the user before
 * they proceed with IVV.
 */
export const consentData: ConsentData = {
  "GLOBAL-1": {
    content: {
      default: {
        Version: "GLOBAL-1 default 1.0",
        Header: "Consent for use of biometrics",
        LegalBody: `
  <p>
    As a part of the process for issuing Amazon system access credentials, Amazon must verify your identity. To do so,
    Amazon utilizes an automated biometric identity verification system (“System”). Use of the System is completely
    optional and voluntary.
</p>

<p>The System works as follows: upload a photo of a government-issued photo identification and a video selfie of yourself. The name on the government-issued Photo ID must match or be substantially similar to the name Amazon has on file. The System verifies your identity by taking biometric measurements of your face (“facial data”) from the video selfie, and comparing it to the facial data from the government-issued Photo ID.</p>

<b><u>What Information is Collected and By Whom</u></b>

<p>Subject to your prior consent, Amazon will provide the following personal information to its third-party service
    provider, Persona Identities, Inc. (“Persona”):
</p>
<ul>
    <li>Your legal full name</li>
</ul>

<p>Amazon does not directly collect your facial data. Persona collects and processes this data and other personal
    information on its behalf to provide the biometric identity verification services as described in this notice.</p>

<p>Persona will collect and/or process the following personal information to verify your identity:</p>
<ul>
    <li>Photo of your government ID;</li>
    <li>A video selfie of you; and</li>
    <li>Facial data (generated from the submitted ID and the video selfie).</li>
</ul>

<p>Your personal information, including your facial data (which may considered sensitive data under applicable local
    law), will be used solely for identification and verification purposes during your initial onboarding with Amazon.
</p>

<p>If you consent and opt in, Amazon and Persona may collect and process your personal information as noted herein from
    the date which you opt in.</p>

<p>Persona will destroy your personal information, including your facial data, within 7 days of completing the
    verification process, unless otherwise required by law. Persona will not share your facial data or any other
    personal information collected from you, with Amazon or any other third party.</p>

<p>Persona will store your personal information in a confidential and secure manner prior to destruction. Amazon has
    contractual measures in place with Persona to protect your information while it is being processed on its behalf.
    Only limited personnel of Persona who have a need to know will have access to the personal information collected.
    Persona may access, store and process your personal information outside of the country, province or region where you
    reside. In this case, the information may be lawfully accessed by foreign courts, law enforcement and governmental
    authorities in accordance with the laws of those other jurisdictions.</p>

<p>For more information on how Amazon processes your personal information, please consult the <a>Amazon HR Privacy Policy|https://www.amazon.jobs/en/privacy_page|gEmployeePrivacyNoticeLink</a>;
    and Persona’s <a>Privacy Notice|https://withpersona.com/legal/privacy-policy|personaPrivacyPolicyLink</a>.</p>
  `,
        Disclaimer: `
    <p>If you wish to provide your consent to proceed to verify your identity with the System, select “Opt In”. By selecting
    “Opt In” you will successfully opt in to use of the System.
    </p>
    <p>
    By checking this box and clicking “Opt In” you acknowledge that you have read and understand the above information
    (which is available at any time upon request), you understand that your decision to verify your identity by use of the
    System is optional and voluntary, you are physically located in the country in which you are employed at this point in
    time, and you consent to, and authorize, the collection and processing of your facial data (including any related
    biometric information) and personal information for the purposes of verifying your identity by the System.
    </p>
    <p>
    You may withdraw your consent to the processing of your personal information, including your facial data, as described
    above at any time provided it has not already been deleted, by contacting Amazon at <a>hr-dsr-privacy@amazon.com|mailto:hr-dsr-privacy@amazon.com|amazonContactEmail</a>.
    </p>
    <p>
    If you do not provide your consent to verifying your identity by the System, your identity will be verified manually by
    your manager or IT support personnel. Selecting manual verification will not affect your employment or be detrimental to
    you in any way.
    </p>
  `,
        ConsentCheck: `
    I consent to the collection and use of my facial data, which is my biometric data for the purposes of automated identity
    verification conducted by the System, by both Amazon and Persona. I also consent to Amazon disclosing my personal data
    as set out in this notice – including my Amazon username and password – to Persona for the purposes of verifying my
    identity. I understand that disclosing my personal data to Persona may require Amazon to transfer my personal data to a
    country outside of my country of residence, and I consent to any such transfer.`,
        LocationCheck: `I am physically located in the country in which I will be employed from this point in time.`,
        BackButton: "Cancel",
        ConsentButton: "Opt In",
      },
    },
  },
  "GLOBAL-2": {
    content: {
      default: {
        Version: "GLOBAL-2 default 1.0",
        Header: "Consent for use of biometrics",
        LegalBody: `
<p>
    As a part of the process for issuing Amazon system access credentials, Amazon must verify your identity. To do so,
    Amazon utilizes an automated biometric identity verification system (“System”). Use of the System is completely
    optional and voluntary.
</p>

<p>The System works as follows: upload a photo of a government-issued photo identification (“Primary Photo ID”), a photo of another government-issued photo ID (“Secondary Photo ID”), and a video selfie of yourself. The name on both the Primary Photo ID and Secondary Photo ID must match or be substantially similar to the name Amazon has on file. The System verifies your identity by taking biometric measurements of your face (“facial data”) from the video selfie, and comparing it to the facial data from both the Primary Photo ID and Secondary Photo ID.</p>

<b><u>What Information is Collected and By Whom </u></b>

<p>Subject to your prior consent, Amazon will provide the following personal information to its third-party service
    provider, Persona Identities, Inc. (“Persona”):</p>
<ul>
    <li>Your legal full name</li>
</ul>

<p>Amazon does not directly collect your facial data. Persona collects and processes this data and other personal
    information on its behalf to provide the biometric identity verification services as described in this notice.</p>

<p>Persona will collect and/or process the following personal information to verify your identity:</p>
<ul>
    <li>Photo(s) of one of your government ID;</li>
    <li>Photo(s) of one other government ID;</li>
    <li>A video selfie of you; and</li>
    <li>Facial data (generated from both the submitted IDs and the video selfie).</li>
</ul>

<p>Your personal information, including your facial data (which may considered sensitive data under applicable local
    law), will be used solely for identification and verification purposes during your initial onboarding with Amazon.
</p>

<p>If you consent and opt in, Amazon and Persona may collect and process your personal information as noted herein from
    the date which you opt in. </p>

<p>Persona will destroy your personal information, including your facial data, within 7 days of completing the
    verification process, unless otherwise required by law. Persona will not share your facial data or any other
    personal information collected from you, with Amazon or any other third party.</p>

<p>Persona will store your personal information in a confidential and secure manner prior to destruction. Amazon has
    contractual measures in place with Persona to protect your information while it is being processed on its behalf.
    Only limited personnel of Persona who have a need to know will have access to the personal information collected.
    Persona may access, store and process your personal information outside of the country, province or region where you
    reside. In this case, the information may be lawfully accessed by foreign courts, law enforcement and governmental
    authorities in accordance with the laws of those other jurisdictions.</p>

<p>For more information on how Amazon processes your personal information, please consult the <a>Amazon HR Privacy Policy|https://www.amazon.jobs/en/privacy_page|gEmployeePrivacyNoticeLink</a>;
    and Persona’s <a>Privacy Notice|https://withpersona.com/legal/privacy-policy|personaPrivacyPolicyLink</a>.</p>
  `,
        Disclaimer: `
    <p>If you wish to provide your consent to proceed to verify your identity with the System, select “Opt In”. By selecting
    “Opt In” you will successfully opt in to use of the System.
    </p>
    <p>
    By checking this box and clicking “Opt In” you acknowledge that you have read and understand the above information
    (which is available at any time upon request), you understand that your decision to verify your identity by use of the
    System is optional and voluntary, you are physically located in the country in which you are employed at this point in
    time, and you consent to, and authorize, the collection and processing of your facial data (including any related
    biometric information) and personal information for the purposes of verifying your identity by the System.
    </p>
    <p>
    You may withdraw your consent to the processing of your personal information, including your facial data, as described
    above at any time provided it has not already been deleted, by contacting Amazon at <a>hr-dsr-privacy@amazon.com|mailto:hr-dsr-privacy@amazon.com|amazonContactEmail</a>.
    </p>
    <p>
    If you do not provide your consent to verifying your identity by the System, your identity will be verified manually by
    your manager or IT support personnel. Selecting manual verification will not affect your employment or be detrimental to
    you in any way.
    </p>
  `,
        ConsentCheck: `
    I consent to the collection and use of my facial data, which is my biometric data for the purposes of automated identity
    verification conducted by the System, by both Amazon and Persona. I also consent to Amazon disclosing my personal data
    as set out in this notice – including my Amazon username and password – to Persona for the purposes of verifying my
    identity. I understand that disclosing my personal data to Persona may require Amazon to transfer my personal data to a
    country outside of my country of residence, and I consent to any such transfer.`,
        LocationCheck: `I am physically located in the country in which I will be employed from this point in time.`,
        BackButton: "Cancel",
        ConsentButton: "Opt In",
      },
    },
  },
  USA: {
    content: {
      default: {
        Version: "USA default 1.0",
        Header: "Consent for use of biometrics",
        LegalBody: `
  <p>Before issuing Amazon system access credentials, Amazon must verify your identity. To do so, Amazon utilizes an automated biometric identity verification system (“System”). Use of the System is completely optional and voluntary. System not for use in Illinois. If you are located in Illinois, please “cancel” and select manual verification.</p> 
  
  <p>The System works as follows: upload a photo of a government-issued photo identification (“Primary Photo ID”), a photo of another government-issued photo ID (“Secondary Photo ID”), and a video selfie of yourself. The name on both the Primary Photo ID and Secondary Photo ID must match or be substantially similar to the name Amazon has on file for you.</p> 
  
  <p>The System verifies your identity by generating biometric measurements of your face (“facial data”) from the video selfie, and comparing that facial data to facial data generated from both the Primary Photo ID and Secondary Photo ID.</p> 
  
  <u><b>What Information is Collected and By Whom</b></u>
  
  <p>To verify your identity, Amazon will provide the following personal information to its third- party service provider, Persona:</p>
  <ul>
    <li>Your legal full name</li>
  </ul>
  
  <p>Persona will collect the following personal information directly from you:</p>
  <ul>
  <li>Photo(s) of your Primary Photo ID,</li>
  <li>Photo(s) of your Secondary Photo ID,</li>
  <li>A video selfie of you, and</li>
  <li>
    Facial data that is generated from both the submitted IDs and the video selfie.
  </li>
  </ul>
  <p>Your personal information and facial data will be used solely for identification and verification purposes. Persona will destroy all personal information and facial data within 7 days of completing the verification process, unless it is required by law to retain your personal information or facial data for a longer period. Persona will not share your personal information or facial data, but it may rely on third-party vendors, including Amazon Web Services, as described in Persona’s <a>Privacy Policy|https://withpersona.com/legal/privacy-policy|personaPrivacyPolicyLink</a>. Persona will store the data in a confidential and secure manner prior to destruction. For more information about Persona’s privacy practices, please review the Persona <a>Privacy Policy|https://withpersona.com/legal/privacy-policy|personaPrivacyPolicyLink</a>.</p>
<p>The above information is available to you at any time upon appropriate request.</p>
<p>For more information, please consult the <a>Amazon Global Employee Privacy Notice|https://www.amazon.jobs/en/privacy_page|gEmployeePrivacyNoticeLink</a> / <a>Amazon California Employee Privacy Policy and Notice|https://amazon.jobs/en/privacy_page?region_id=region_california&ice_id=us-privacy-english|caEmployeePrivacyNoticeLink</a>.</p>
`,
        Disclaimer:
          "To use the System for identity verification, you must check both boxes below and then click “Next.” If you do not wish to use the System, do not check the boxes below. Please do not click “Next.” Instead, please “cancel” and select manual verification and your identity will be verified manually by your manager or IT support personnel. Selecting manual verification will not impact employment.",
        ConsentCheck: `I consent to Amazon’s and Persona’s collection, use, and storage of my personal information and facial data, including data that could be considered biometric data under the laws of some jurisdictions, as described above. I also consent for the automated identity verification to receive my Amazon username and password.`,
        LocationCheck: `I am physically located in the country in which I will be employed from this point in time.`,

        BackButton: "Cancel",
        ConsentButton: "Agree",
      },
    },
  },
  EU: {
    content: {
      default: {
        Version: "EU default 1.0",
        Header: "Facial Identity Verification Consent",
        LegalBody: `
                <p>
    Before issuing your Amazon system access credentials, the 
    Amazon entity you work for (“<b>Amazon</b>”) must verify your identity. 
    To complete this step, you can either choose an automated biometric identity 
    verification system (“<b>System</b>”) or a human verification method (see below).
</p>
<p>
    <b><u>How the System Works</u></b>
    <p>
        The System works as follows: We ask you to upload a government-issued ID 
        that includes a photo of you (“Photo ID”) and a video selfie of yourself. 
        The name on the Photo ID must match or be substantially similar to the name 
        Amazon has on file. The System verifies your identity by comparing the 
        biometric measurements of your face (“facial data”) from the video selfie 
        with the facial data from the Photo ID.
    </p>
</p>
<p>
    <b><u>What Information is Collected and By Whom</u></b>
    <p>
        If you decide to verify your identity by the System, 
        our third-party service provider, Persona Identities, Inc. 
        (“<b>Persona</b>”), will collect and process the following personal 
        information on our behalf:
        <ul>
            <li>Your legal full name</li>
            <li>Your Amazon user name and password</li>
            <li>Photo ID provided by you;</li>
            <li>A video selfie of you; and</li>
            <li>Facial data (generated from both the submitted Photo ID and the video selfie).</li>
        </ul>
    </p>
</p>
<p>
    Persona will not share your facial data or any other personal information 
    collected from you, with Amazon or any other third party. Amazon will 
    only be informed whether you passed or failed the verification. If the 
    verification fails, there will be further human review, the System doesn’t 
    take any automated decisions. Your personal information, including your 
    facial data, will be used solely for identification and verification purposes 
    during your initial onboarding with Amazon.
</p>
<p>
    <b><u>How Data is Stored and Transferred</u></b>
    <p>
        Persona will store your personal information in a confidential and 
        secure manner on servers located in the European Economic Area (EEA). 
        Once the verification is completed, Persona will destroy your personal 
        information, including your facial data, within 7 days. Amazon has 
        contractual measures in place with Persona to protect your information 
        while it is being processed on its behalf. Only limited personnel of 
        Persona who have a need to know will have access to the personal 
        information collected. If your personal information is accessed by 
        personnel of Persona outside the EEA, there are appropriate safeguards 
        in place through EU Standard Contractual Clauses based on the European 
        Commission’s model clauses.
    </p>
</p>
<p>
    For more information on how Amazon processes your personal data, please consult the 
    Amazon HR Privacy Notice provided as part of your onboarding package, or through the 
    internal Amazon portal, AtoZ, upon completing the laptop setup process. Persona’s Privacy 
    Notice is available <a>here|https://withpersona.com/legal/privacy-policy|personaPrivacyPolicyLink</a>.
</p>
                `,
        Disclaimer: `
    <b><u>How to Consent to the System</u></b>
    <p>To proceed, please check one of the boxes below:</p>
    <ul>
        <li>
        By checking the consent box, you consent to the collection and processing of your personal information, including your facial data, to verify your identity by the System. You further acknowledge that you have read and understand the above information (which is available at any time upon request) and you are physically located in the country in which you are employed at this point in time. You understand that your decision to verify your identity by the System is optional and voluntary.
        </li>
        <li>
        If you decide to not provide your consent to verify your identity by the System, please opt for the human verification method. Your identity will then be verified manually by your manager or IT support personnel. Choosing a human verification method will not affect your employment or be detrimental to you in any way.
        </li>
    </ul>
    <p>
        You may withdraw your consent to the processing of your personal information, including your facial data, at any time, by contacting us at <a>hr-dsr-privacy@amazon.com|mailto:hr-dsr-privacy@amazon.com|amazonContactEmail</a>.
    </p>
                `,
        ConsentCheck: `I consent to the collection and processing of my personal information, including my facial data, to verify my identity by the System, by both Amazon and Persona. I also consent to Amazon disclosing my personal information as set out in this notice – including my Amazon username and password – to Persona to verify my identity.`,
        LocationCheck: `I am physically located in the country in which I will be employed from this point in time.`,
        BackButton: "Cancel",
        ConsentButton: "Agree",
      },
    },
  },
};
